@import 'fonts';
@import 'variables';

:root {
  --app-height: 100%;
}

html,
body {
  padding: 0;
  margin: 0;
  width: 100vw;
  min-height: 100vh;
  height: var(--app-height);
  max-width: 100%;

}

body {
  font-family: 'Montserrat', sans-serif !important;
  color: #272727 !important;
}

.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

// height
.h-10 {
  height: 10vh;
}

.h-15 {
  height: 15vh;
}

.h-20 {
  height: 20vh;
}

.h-40 {
  height: 40vh;
}

.w-20 {
  width: 20vh;
}

.main-hero {
  height: 100vh;
  background-image: url(../assets/main-hero-background-sm.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (min-width: 768px) {
    background-image: url(../assets/main-hero-background-l.png);
  }
}

.row-logo {
  height: 20vh;

  @media (min-width: 1200px) {
    height: 50vh;
  }
}

.arrow {
  width: 1.375rem;
  height: auto;

  @media (min-width: 768px) {
    width: 2rem;
  }
}

.bounce {
  -moz-animation: bounce 3s infinite;
  -webkit-animation: bounce 3s infinite;
  animation: bounce 3s infinite;
}

@-moz-keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -moz-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  60% {
    -moz-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

@-webkit-keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  60% {
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

.btn.btn-outline-vsh {
  border: 2px solid #23a0df;
  border-radius: 100px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  // line-height: 22px;
  color: #23a0df;
  width: 138px;
  height: 42px;
}

.btn.btn-outline-vsh:hover,
.btn.btn-outline-vsh:active,
.btn.btn-outline-vsh:focus,
.btn.btn-outline-vsh.active {
  background: #23a0df;
  color: #ffffff;
  border-color: #23a0df;
}

.btn.btn-vsh {
  border: 2px solid #23a0df;
  background: #23a0df;
  color: white;
  border-radius: 60px;
  width: 179px;
  height: 30px;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1.0625rem;


  &.btn-vsh-lg {
    width: 218px;


    @media (min-width: 768px) {
      width: 350px;
      // padding-top: 12px;
      padding-top: 9px;
      padding-bottom: 9px;

    }
  }

  &.btn-vsh-xl {
    width: 350px;


    @media (min-width: 768px) {
      width: 488px;
      // padding-top: 12px;
      padding-top: 9px;
      padding-bottom: 9px;

    }
  }

  &.btn-arrow {
    @media (min-width: 768px) {

      padding-top: 12px;

    }

    &.ar-button {
      width: 250px;
    }

    &.easter-button {
      position: absolute;
      left: 50%;
    }
  }

  @media (min-width: 768px) {
    font-size: 1.25rem;
    line-height: 75%;
    width: 274px;
    height: 50px;
    padding-top: 9px;
    padding-bottom: 9px;

  }

  & img {
    width: 15px;

    @media (min-width: 992px) {
      width: 27px;
    }
  }
}

.btn.btn-vsh:hover,
.btn.btn-vsh:active,
.btn.btn-vsh:focus,
.btn.btn-vsh.active {
  background: white;
  color: #23a0df;
  border: 2px solid #23a0df;
}

.btn.btn-vsh:hover img,
.btn.btn-vsh:active img,
.btn.btn-vsh:focus img,
.btn.btn-vsh.active img {
  filter: brightness(0) saturate(100%) invert(50%) sepia(91%) saturate(1320%) hue-rotate(170deg) brightness(94%) contrast(85%);
}

.btn.btn-vsh-light {
  border: 2px solid #23a0df;
  background: #EBF3F7;
  color: #23a0df;
  border-radius: 50px;
  height: 30px;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 0.04rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 12px;

  @media (min-width: 768px) {
    font-size: 1.25rem;
    line-height: 75%;
    width: 274px;
    height: 50px;
    padding-top: 15px;
  }

}

.btn.btn-vsh-light:hover,
.btn.btn-vsh-light:active,
.btn.btn-vsh-light:focus,
.btn.btn-vsh-light.active {
  background: #23a0df;
  color: #EBF3F7;
  border: 2px solid white;
}

a.btn-sample {
  color: #000;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.15em;
  display: inline-block;
  padding: 15px 20px;
  position: relative;

  &:hover {
    text-decoration: none;
  }
}

a.btn-sample:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 0%;
  position: absolute;
  background: #000;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 100%;
}

a.btn-sample:hover:after {
  width: 0;
  left: 50%;
}

#navbar {
  transition: top 0.3s;
}

.nav-logo-s {
  width: 240px;

  @media (min-width: 768px) {
    width: 300px;
  }

  @media (max-width: 321px) {
    width: 200px;
  }
}

.nav-row {
  height: 95vh;
}

// .nav-row div:last-child {
//   margin-left: auto;
//   padding-left: 10vw;

// }

.nav-hamburger {
  width: 18px;
  margin-right: 5vw !important;

  @media (min-width: 768px) {
    width: 30px;
  }
}

.navbar-toggler>.nav-close {
  display: inline;
  width: 18px;
  margin-right: 5vw !important;

  @media (min-width: 768px) {
    width: 30px;
  }
}

.navbar-toggler.collapsed>.nav-close,
.navbar-toggler:not(.collapsed)>.nav-hamburger {
  display: none;
}

.navbar-light .navbar-collapse .navbar-nav .nav-link {
  color: #272727;
}

.navbar-nav {
  width: 100%;
  height: 100vh !important;

  @media (min-width: 992px) {
    height: 70px !important;
  }
}


.navbar-light .navbar-toggler {
  border-color: transparent !important;
}

.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: #23a0df !important;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: #23a0df !important;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  border: none;
  background-color: #ebf3f7;
  padding-left: 10vh;
  padding-right: 10vh;

  @media (min-width: 992px) {
    box-shadow: 4px 4px 8px rgba(4, 59, 85, 0.05), -2px -2px 4px rgba(4, 59, 85, 0.05);
    border-radius: 10px;
    padding: 30px !important;
    width: 239px;
    background-color: white !important;
  }
}

.dropdown img {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding-left: 0 !important;
  padding-right: 0;
}

.navbar-brand {
  padding-left: 5vw !important;
}

.dropdown a[aria-expanded="true"] img {
  transform: rotate(180deg);
  // margin-left: 0.5rem;
}

.dropdown:hover img,
.dropdown:active img,
.dropdown:focus img,
.dropdown.active img {
  filter: invert(50%) sepia(91%) saturate(1320%) hue-rotate(170deg) brightness(94%) contrast(85%);
}

.dropdown-item {
  @media (min-width: 992px) {
    white-space: pre-wrap !important;
  }

  &.dropdown-item-line {
    @media (min-width: 992px) {
      line-height: 150% !important;
    }
  }
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: transparent !important;
  color: #23a0df !important;
}

// .dropdown:hover .dropdown-menu {
//   display: block;
//   margin-top: 0;
// }


.vr-line-height {
  line-height: 1.744rem;
}

$thick: 2.76px;
$thick-lg : 5px;
$pad : 0.7em;
$extra : calc(#{$pad} * 1.2);


a.btn-vsh-text {
  padding: $pad $extra;
  display: inline-block;
  border: $thick solid transparent;
  border-radius: 30%;
  position: relative;
  cursor: pointer;
  color: #272727;
  // width: 220px;
  height: 50px;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 250%;
  padding: $thick $extra;

  & .small-caps {
    // letter-spacing: 0.268634px;
    // font-variant: small-caps;
    text-transform: uppercase;
    font-size: 0.75rem;

    @media (min-width: 768px) {
      font-size: 1.25rem;
    }
  }

  &.left {
    margin-left: -12px;

    @media (min-width: 992px) {
      margin-left: -17px;
    }
  }

  &.right {
    margin-right: -12px;

    @media (min-width: 992px) {
      margin-right: -17px;
    }
  }

  &.btn-niepod {
    margin-left: -15px;
  }

  @media (min-width: 768px) {
    font-size: 1.25rem;
    line-height: 275%;
    // width: 350px;
    height: 70px;
    font-weight: 600;
  }

  @media (min-width: 992px) {
    border: $thick-lg solid transparent;
    padding: $thick-lg $extra;

  }

  .text {

    transform: translate3d(0, $pad, 0);
    display: flex;
    justify-content: space-between;
    transition: transform 0.4s cubic-bezier(.2, 0, 0, 1) 0.4s;

    & img {
      filter: brightness(0) saturate(100%);
      width: 15px;
      margin-left: 6px;

      @media (min-width: 992px) {
        width: 27px;
      }
    }
  }

  &:after {
    position: absolute;
    content: '';
    bottom: -$thick;
    left: $extra;
    right: $extra;
    height: $thick;
    background: #23a0df;
    // z-index: -1;
    transition:
      transform 0.8s cubic-bezier(1, 0, .37, 1) 0.2s,
      right 0.2s cubic-bezier(.04, .48, 0, 1) 0.6s,
      left 0.4s cubic-bezier(.04, .48, 0, 1) 0.6s;
    transform-origin: left;

    @media (min-width: 992px) {
      bottom: -$thick-lg;
      height: $thick-lg;
    }
  }

}

a.btn-underline {
  color: #272727;
  height: 50px;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 250%;
  display: inline-block;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1.0625rem;

  .text {
    transform: translate3d(0, $pad, 0);
    display: flex;
    justify-content: space-between;
    transition: transform 0.4s cubic-bezier(.2, 0, 0, 1) 0.4s;

    & img {
      filter: brightness(0) saturate(100%);
      width: 15px;
      padding-left: 6px;


      @media (min-width: 992px) {
        width: 27px;
        padding-left: 6px;
      }
    }
  }
}

.line {
  position: absolute;
  background: #23a0df;

  &.-right,
  &.-left {
    width: $thick;
    bottom: -$thick;
    top: -$thick;
    transform: scale3d(1, 0, 1);

    @media (min-width: 992px) {
      width: $thick-lg;
      bottom: -$thick-lg;
      top: -$thick-lg;
    }
  }

  &.-top,
  &.-bottom {
    height: $thick;
    left: -$thick;
    right: -$thick;
    transform: scale3d(0, 1, 1);

    @media (min-width: 992px) {
      height: $thick-lg;
      left: -$thick-lg;
      right: -$thick-lg;
    }
  }

  &.-right {
    right: -$thick;
    transition: transform 0.1s cubic-bezier(1, 0, .65, 1.01) 0.23s;
    transform-origin: top;

    @media (min-width: 992px) {
      right: -$thick-lg;
    }
  }

  &.-top {
    top: -$thick;
    transition: transform 0.08s linear 0.43s;
    transform-origin: left;

    @media (min-width: 992px) {
      top: -$thick-lg;
    }
  }

  &.-left {
    left: -$thick;
    transition: transform 0.08s linear 0.51s;
    transform-origin: bottom;

    @media (min-width: 992px) {
      left: -$thick-lg;
    }
  }

  &.-bottom {
    bottom: -$thick;
    transition: transform 0.3s cubic-bezier(1, 0, .65, 1.01);
    transform-origin: right;

    @media (min-width: 992px) {
      bottom: -$thick-lg;
    }
  }
}

a.btn-vsh-text:hover,
a.btn-vsh-text:active {
  text-decoration: none;
  color: #272727;

  .text {
    transform: translate3d(0, 0, 0);
    transition: transform 0.6s cubic-bezier(.2, 0, 0, 1) 0.4s;
  }

  &:after {
    transform: scale3d(0, 1, 1);
    right: -$thick;
    left: -$thick;
    transform-origin: right;
    transition:
      transform 0.2s cubic-bezier(1, 0, .65, 1.01) 0.17s,
      right 0.2s cubic-bezier(1, 0, .65, 1.01),
      left 0s 0.3s;

    @media (min-width: 992px) {
      right: -$thick-lg;
      left: -$thick-lg;
    }
  }

  .line {
    transform: scale3d(1, 1, 1);

    &.-right {
      transition: transform 0.1s cubic-bezier(1, 0, .65, 1.01) 0.2s;
      transform-origin: bottom;
    }

    &.-top {
      transition: transform 0.08s linear 0.4s;
      transform-origin: right;
    }

    &.-left {
      transition: transform 0.08s linear 0.48s;
      transform-origin: top;
    }

    &.-bottom {
      transition: transform 0.5s cubic-bezier(0, .53, .29, 1) 0.56s;
      transform-origin: left;
    }
  }

}

.btn-group-wrapper {
  width: 200px;
  height: 36px;
  background: #EBF3F7;
  border: 0.440637px solid rgba(35, 160, 223, 0.15);
  box-shadow: 4.40637px 4.40637px 8.81273px rgba(65, 178, 234, 0.12), -4.40637px -4.40637px 8.81273px #F5FCFF;
  border-radius: 4.40637px;

  @media (min-width: 992px) {
    width: 454px;
    height: 84px;
    border: 1px solid rgba(35, 160, 223, 0.15);
    box-shadow: 10px 10px 20px rgba(65, 178, 234, 0.12), -10px -10px 20px #F5FCFF;
    border-radius: 10px;
  }
}

.toggleWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

.toggleWrapper input {
  position: absolute;
  left: -99em;
}

.toggle {
  cursor: pointer;
  display: inline-block;
  position: relative;
  width: 190px;
  height: 26px;
  border-radius: 5px;
  margin-bottom: 0;
  -webkit-transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);

  @media (min-width: 992px) {
    width: 454px;
    height: 60px;
  }
}

.toggle:before,
.toggle:after {
  position: absolute;
  line-height: 33px;
  font-size: 13.2px;
  font-weight: 700;
  z-index: 2;
  -webkit-transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);

  @media (min-width: 992px) {
    font-size: 30px;
    line-height: 70px;
  }
}

.toggle:before {
  content: "VR";
  left: 40px;
  color: white;

  @media (min-width: 992px) {
    left: 98px;
  }
}

.toggle:after {
  content: "AR";
  right: 40px;
  color: #23a0df;

  @media (min-width: 992px) {
    right: 98px;
  }
}

.toggle__handler {
  display: inline-block;
  position: relative;
  z-index: 1;
  background: #23a0df;
  border: 0.881273px solid #69BFEA;
  width: 100px;
  height: 26px;
  border-radius: 5px;
  top: 3px;
  -webkit-transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  -webkit-transform: translateX(0px);
  transform: translateX(0px);

  @media (min-width: 992px) {
    width: 222px;
    height: 60px;
    border: 1px solid #69BFEA;
    left: 12px;
    border-radius: 10px;
  }
}

input:checked+.toggle:before {
  color: #23a0df
}

input:checked+.toggle:after {
  color: white;
}

input:checked+.toggle .toggle__handler {
  width: 100px;
  -webkit-transform: translateX(90px);
  transform: translateX(90px);

  @media (min-width: 992px) {
    width: 222px;
    -webkit-transform: translateX(210px);
    transform: translateX(210px);
  }
}

.vr-360 {
  height: 400px;
  width: 100vw;

  @media (min-width: 992px) {
    height: 524px;
  }

  a-scene {
    height: 400px;
    width: 100vw;

    @media (min-width: 992px) {
      height: 524px;
    }
  }
}

.vr-360-funky {
  height: 80vh;
  width: 100vw;

  @media (min-width: 992px) {
    height: 80vh;
  }

  a-scene {
    height: 80vh;
    width: 100vw;

    @media (min-width: 992px) {
      height: 80vh;
    }
  }
}


.half-col-xxl {
  @media (min-width: 1800px) {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
}

.ar-margin-minus {
  margin-top: -10 !important;

  @media (min-width: 992px) {
    margin-top: -20% !important;
  }

  @media (min-width: 2000px) {
    margin-top: -10% !important;
  }
}

.video-bg {
  // position: absolute;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: auto;
}

.card {
  width: 16rem;

  @media (min-width: 768px) {
    width: 29.188rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  &.card-niepod {
    @media (min-width: 768px) {
      width: 16rem;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }

    @media (min-width: 992px) {
      width: 29.188rem;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }
}

.col-move-on {
  @media (min-width: 992px) {
    margin-right: -4rem;
  }
}

.a-enter-vr-button {
  display: none;

  @media (min-width: 992px) {
    display: block;
  }
}

.logo {
  filter: grayscale(1) brightness(1.2);
  max-height: 150px;

  &:hover {
    filter: none;
  }
}

.logo-black {
  filter: invert(29%) sepia(9%) saturate(432%) hue-rotate(182deg) brightness(91%) contrast(85%);
  max-height: 150px;

  &:hover {
    filter: none;
  }
}

.btn-footer {
  margin-left: -15px;

  @media (min-width: 992px) {
    margin-left: -25px;
  }
}

.row-social-icons {
  width: 156px;
}

.row-social-icons-main {
  width: 235px;

  img {
    filter: brightness(0) saturate(100%);
  }
}

.social-icon {
  width: 20px;

  @media (min-width: 992px) {
    width: auto;
  }

}

.a-enter-ar-button,
.a-enter-vr-button {
  z-index: 1000;
}

#ar {
  display: none;
}

.row-vac-title {
  margin-top: -170vh;

  @media (min-width: 380px) {
    margin-top: -150vh;
  }

  @media (min-width: 415px) {
    margin-top: -90vh;
  }

  @media (min-width: 768px) {
    margin-top: -90vh;
  }

  @media (min-width: 1200px) {
    margin-top: -200vh;
  }

  @media (min-width: 1400px) {
    margin-top: -230vh;
  }
}

.phone-container {
  position: relative;
  text-align: center;
  color: white;
}

.centered {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
}


.row-vac-card {
  margin-top: -7vh;

  @media (min-width: 768px) {
    margin-top: 2vh;
  }

  @media (min-width: 1200px) {
    margin-top: -70vh;
  }

  .card {
    @media (min-width: 768px) {
      width: 29.188rem !important;
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
    }
  }
}

.row-after-vac {
  @media (min-width: 1200px) {
    margin-top: 50vh;
  }
}

.ar-vac {
  margin-bottom: -20vh;
  z-index: 2;

}

.ar-bg {
  background-image: url(../assets/ar-bg-sm.png);
  background-size: cover;
  background-repeat: no-repeat;

  @media (min-width: 992px) {
    background-image: url(../assets/ar-bg.png);
    background-position: left top;
    height: 900px;
  }
}

#wolf-video {
  right: 0;
  bottom: 0;
  width: auto;
  height: 325px;

  @media (min-width: 992px) {
    height: 760px;
  }
}

#wolf-video-container {
  position: relative;

  @media (min-width: 1200px) {}
}

#wolf-phone-image {
  background-image: url(../assets/ar-phone.png);
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  width: 178px;
  min-height: 360px;
  z-index: 300000;
  top: 35px;
  left: 5px;
  text-align: center;

  @media (min-width: 992px) {
    width: 410px;
    height: 827px;
    top: 16px;

  }
}



.triangle-bg {
  background-color: #ebf3f7;
  background-image: url(../assets/triangles-sm.png);
  background-size: cover;
  background-repeat: no-repeat;

  @media (min-width: 992px) {
    background-image: url(../assets/triangles.png);

  }
}

//ABOUT

.about-img-1 {
  @media (min-width: 1200px) {
    max-width: 325px !important;

  }
}

.about-img-2 {
  @media (min-width: 1200px) {
    max-width: 355px !important;
  }
}

.about-img-3 {
  @media (min-width: 1200px) {
    max-width: 450px !important;
  }
}

.about-img-4 {
  @media (min-width: 1200px) {
    max-width: 380px !important;
  }

}

.about-philospohy {
  max-height: 270px;
}

//sercices ar

.ar-bg {
  background-image: url(../assets/services-ar-text.png);
  background-position: right;
  background-repeat: no-repeat;
  background-size: 200px;

  @media (min-width: 992px) {
    background: none;
    background-size: 0px;
  }

}


.threed-bg {
  background-image: url(../assets/services-3d-3d-text.png);
  background-position: right;
  background-repeat: no-repeat;
  background-size: 200px;

  @media (min-width: 992px) {
    background: none;
    background-size: 0px;
  }

}

.vrexp-bg {
  background-image: url(../assets/services-vrexp-text.png);
  background-position: right;
  background-repeat: no-repeat;
  background-size: 200px;

  @media (min-width: 992px) {
    background: none;
    background-size: 0px;
  }

}

.games-bg {
  background-image: url(../assets/services-games-text.png);
  background-position: right;
  background-repeat: no-repeat;
  background-size: 200px;

  @media (min-width: 992px) {
    background: none;
    background-size: 0px;
  }

}

.vrexp-border {
  border-bottom: 2px solid #EBF3F7;

  @media (min-width: 992px) {
    border-bottom: 3.5px solid #EBF3F7;
  }
}

.ar-border {
  border-bottom: 6px solid #EBF3F7;

  @media (min-width: 992px) {
    border-bottom: 10px solid #EBF3F7;
  }
}

.ar-chair {
  width: 1000px;
  height: 1000px;
}

.ar-mobile-devices {
  max-height: 300px;
}

.ar-browser-icons {
  max-height: 120px;
}

#ar-phone-config {
  max-height: 604px;
}


.slider-bg {
  background-image: url(../assets/services-ar-slider-bg-sm.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 700px;
  background-position: center center;
  position: relative;


  @media (min-width: 768px) {
    background-image: url(../assets/services-ar-slider-bg.png);
  }
}

.slider-phone-bg {
  @media (min-width: 768px) {
    background-image: url(../assets/services-ar-slider-phone.png);
    background-size: auto;
    background-repeat: no-repeat;
    height: 613px;
    width: 300px !important;
    background-position: center center;
    z-index: 10;
    position: absolute;
  }
}

.carousel-inner.games img {
  max-width: 100%;
  height: auto;
}

.carousel-inner.ar {
  width: 299px !important;
  height: 600px !important;
}

.carousel-item img {
  border-radius: 2%;
}


.carousel.carousel-ar {
  margin-left: 50px;
  margin-right: 50px;

  .carousel-control-prev {
    margin-left: -60px !important;
  }

  .carousel-control-next {
    margin-right: -60px !important;
  }
}

.carousel.carousel-ar .carousel-control-next-icon {
  background-image: url("../assets/ar-next-arrow.png");
  width: 25px;

  @media (min-width: 768px) {
    width: 37px;
    height: 25px;
  }

}

.carousel.carousel-ar .carousel-control-prev-icon {
  background-image: url("../assets/ar-prev-arrow.png");
  width: 25px;


  @media (min-width: 768px) {
    width: 37px;
    height: 25px;
  }
}



.games-text {
  font-size: 40px;
  color: rgba(235, 243, 247, 0.75);
  font-weight: bold;
  font-family: Montserrat, "Helvetica Neue", ;
}


.carousel.models .carousel-control-next {
  left: 40%;
}

.carousel.models .carousel-control-prev {
  left: 20%;
}


button:focus {
  outline: 0;
}

@keyframes anim-glow {
  0% {
    box-shadow: 0 0 rgba(#23a0df, 1);
  }

  100% {
    box-shadow: 0 0 30px 24px transparent;
    border-width: 6px;
  }
}

// .xrayrow {
//   @media (min-width: 768px) {

//     height: 445px !important;

//     }
// }

.xraycont {
  position: relative;
  width: 300px !important;
  height: 445px !important;

  @media (min-width: 768px) {
    width: 447px !important;
    height: 662px !important;

  }

  #xraybutton {
    position: absolute;
    top: 175.16px;
    left: 180.02px;
    background-color: #fff;
    color: #fff;
    font-size: 30px;
    width: 60px;
    height: 60px;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    animation: anim-glow 2s ease infinite;
    text-align: center;

    @media (min-width: 768px) {
      color: #C4C4C5;
      top: 260.16px;
      left: 260.02px;
      font-size: 65px;
      width: 80px;
      height: 80px;
    }
  }
}

.ar-01,
.ar-02,
.ar-03 {
  height: 60px;
  background-position: left center;
  background-size: auto 60px;
  background-repeat: no-repeat;

  @media (min-width: 992px) {
    height: 100px;
    background-size: auto 100px;
  }
}

.ar-01 {
  background-image: url(../assets/services-ar-01.png);
}

.ar-02 {
  background-image: url(../assets/services-ar-02.png);
}

.ar-03 {
  background-image: url(../assets/services-ar-03.png);
}


.userInput {
  border: none;
  height: 500px;
}

.contact-block {
  width: 145px;
  height: 145px;
  border: 1.57202px solid #272727;
  border-radius: 7.86008px;

  div {
    line-height: 75%;
  }

  @media (min-width: 768px) {
    width: 160px;
    height: 160px;
    border: 1.77778px solid #272727;
    border-radius: 8.88889px;
  }

  &:hover {
    background-color: #ebf3f7;
  }
}

.section-form form {
  .form-control::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #c4c4c5 !important;
    opacity: 1;
    /* Firefox */
  }

  .form-control:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #c4c4c5 !important;
  }

  .form-control::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #c4c4c5 !important;
  }

  input:not([type=checkbox]),
  textarea,
  input[type=text],
  input[type=phone],
  input[type=email],
  select.form-control {
    background: transparent;
    border: none;
    border-bottom: 2px solid #272727;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    width: 256px;

    @media (min-width: 768px) {
      width: 346px;
    }

    &:focus {
      -webkit-box-shadow: none;
      box-shadow: none;
      border-color: #23a0df;
    }
  }

  .btn-vsh {
    width: 94px;

    @media (min-width: 768px) {
      width: 174px;
    }
  }
}

input.visually-hidden {
  width: 1px !important;
  height: 1px !important;
}

.visually-hidden {
  opacity: 0;
}

.contact-icon {
  max-width: 25px !important;
}

.form-check-input {
  margin-top: 0.2rem !important;

  @media (min-width: 992px) {
    margin-top: 0.3rem !important;
  }
}

#check-vr:checked~label .contact-block.vr,
#check-ar:checked~label .contact-block.ar,
#check-other:checked~label .contact-block.other,
#check-quest:checked~label .contact-block.quest {
  background-color: #23a0df;
}

.contact-border-bottom {
  border: 1px solid #272727;

  @media (min-width: 992px) {
    border: 2px solid #272727;
  }
}

.section-footer {
  .social-icon {
    cursor: pointer;
    width: 23px !important;
    filter: invert(36%) sepia(11%) saturate(269%) hue-rotate(184deg) brightness(93%) contrast(97%);

    @media (min-width: 992px) {
      width: 30px !important;
    }

    &:hover {
      filter: invert(50%) sepia(82%) saturate(919%) hue-rotate(169deg) brightness(93%) contrast(87%);
    }
  }

  a {
    color: #55585f;

    &:hover {
      color: #23a0df;
    }
  }

}

.link,
.link:hover {
  height: 30px;
  width: 180px;
  align-items: center;
  color: #272727;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  border-bottom: 2.76448px solid #23A0DF;
  padding-bottom: 10px;
  font-size: 14px;

  @media (min-width: 992px) {
    width: 235px;
  }
}

/* Hide extra text */
.mask {
  position: relative;
  padding: 0;
  height: 25px;

  /*  Remove overflow to see how it works　:) */
  overflow: hidden;
}

.link-container {
  transition: transform 0.4s ease;
}

.title {
  display: block;

  /*  Set same font-size and line height  */
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;

  transition: transform 0.4s ease;

  @media (min-width: 992px) {
    font-size: 20px;
  }
}


.link-title1 {
  transform-origin: right center;
}

.link-title2 {
  transform-origin: left center;
  transform: rotate(40deg);
  color: #23a0df;
}

.link-icon {
  position: relative;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;

  /*  Remove overflow to see how it works　:) */
  overflow: hidden;
}

.icon {
  display: block;
  position: absolute;
  transition: transform 0.4s ease;
}

.icon {
  filter: brightness(0) saturate(100%);
}

.icon:nth-child(2) {
  transform: translate(-40px);
  filter: brightness(0) saturate(100%) invert(50%) sepia(91%) saturate(1320%) hue-rotate(170deg) brightness(94%) contrast(85%);
}

/* Hover Action*/

/* Move up two texts (20px = font-size) */
.link:hover .link-container {
  transform: translateY(-20px);
}

/* Rotate texts a little bit */
.link:hover .link-title1 {
  transform: rotate(20deg);
}

.link:hover .link-title2 {
  transform: rotate(0);
}

/* Move arrows to right */
.link:hover .icon:first-child {
  transform: translate(40px);
}

.link:hover .icon:nth-child(2) {
  transform: translate(0px);
}

.nav-link:focus {
  outline: none;
}


.process-img {
  width: 250px;
  height: 226px;
  // justify-content: center !important;
  // align-items: end !important;

  @media (min-width: 768px) {

    width: 448px;
    height: 295px;
  }
}

#logos-container {
  height: 120px;
  position: relative;
  overflow: hidden;
}

.photobanner {
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
  white-space: nowrap;
  animation: bannermove 30s linear infinite;

  .logo-slider {
    max-height: 100px;
  }
}

.photobanner img {
  margin: 0 2rem;
}

@keyframes bannermove {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-50%, 0);
  }
}

.min-mar {
  margin-top: -25vh;
}

// CONFIG


.config-img-text-ar {
  width: 48px;

  @media (min-width: 768px) {
    width: 144px;
  }
}

.config-img-percent {
  height: 49px;

  @media (min-width: 768px) {
    max-height: 82px;
  }
}

.config-carousel-item {
  border: 1px solid #272727;
  border-radius: 15px;
}


.carousel .config-carousel-item.config-carousel-item-your-config {
  height: 255px;
  width: 311px;

  @media (min-width: 768px) {
    height: 580px;
    width: 1100px;
  }
}

.carousel .carousel-config .carousel-control-next-icon {
  background-image: url("../assets/config-next-arrow.png");
  width: 25px;
  transform: translateY(1000%);

  @media (min-width: 768px) {
    width: 37px;
    height: 25px;
    transform: translateY(950%);
  }

}

.carousel .carousel-config .carousel-control-prev-icon {
  background-image: url("../assets/config-prev-arrow.png");
  width: 25px;
  transform: translateY(1000%);


  @media (min-width: 768px) {
    width: 37px;
    height: 25px;
    transform: translateY(950%);
  }
}

.carousel .carousel-your-config .carousel-control-next-icon {
  background-image: url("../assets/config-next-arrow.png");
  width: 25px;
  transform: translateY(750%);

  @media (min-width: 768px) {
    width: 37px;
    height: 25px;
    transform: translateY(1300%);
  }

}

.carousel .carousel-your-config .carousel-control-prev-icon {
  background-image: url("../assets/config-prev-arrow.png");
  width: 25px;
  transform: translateY(750%);


  @media (min-width: 768px) {
    width: 37px;
    height: 25px;
    transform: translateY(1300%);
  }
}

.carousel-models .carousel-control-next-icon {
  background-image: url("../assets/config-next-arrow.png");
  width: 25px;
  transform: translateX(350%);



  @media (min-width: 768px) {
    width: 37px;
    height: 25px;
    transform: translateX(200%);
  }
}

.carousel-models .carousel-control-prev-icon {
  background-image: url("../assets/config-prev-arrow.png");
  width: 25px;



  @media (min-width: 768px) {
    width: 37px;
    height: 25px;
  }
}

.carousel .carousel-config.carousel-control-next,
.carousel .carousel-your-config.carousel-control-next {
  left: 89%;
  opacity: 1;

  @media (min-width: 768px) {
    left: 90%;
  }

  @media (min-width: 992px) {
    left: 91%;
  }
}

// .carousel .carousel-control-next {
//   // bottom: -120%;
//   left: 90%;
//   opacity: 1;
// }

.carousel .carousel-config.carousel-control-prev,
.carousel .carousel-your-config.carousel-control-prev {
  left: 80%;
  opacity: 1;

  @media (min-width: 768px) {
    left: 83%;
  }

  @media (min-width: 992px) {
    left: 85%;
  }
}

// .carousel .carousel-item {

//   @media (min-width: 992px) {
//   max-height: 581px; /* Dostosuj wysokość do swoich preferencji */
//   overflow: hidden; /* Zapobiegnie to wydłużaniu się elementów */
// }
// }



.carousel .config-browser-img {
  max-height: 43px;
  max-width: 100%;

  @media (min-width: 768px) {
    max-height: 120px;

  }


}

.config-mac-img {
  height: 140px !important;

  @media (min-width: 768px) {
    height: 371px !important;

  }


}

.config-ipad-img {
  height: 100px !important;

  @media (min-width: 768px) {
    height: 267px !important;
  }


}

.config-formats-img {
  max-height: 150px;

  @media (min-width: 768px) {
    max-height: 300px;
  }
}

.part-right {
  border: 1px solid #000;
  border-radius: 10px 0 0 10px;
}


.carousel-bg {
  @media (min-width: 768px) {
    background-image: url(../assets/config-slider-bg.png);
    background-size: contain;
    background-repeat: no-repeat;
    height: 530px;
    width: 68px;
    background-position: bottom -0px left 200px;
    z-index: 10;
    position: absolute;
  }
}

.section-variants .config-circle {
  background-image: url("../assets/config-circle.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 98px;
  height: 98px;

  @media (min-width: 768px) {
    width: 158px !important;
    height: 158px !important;
  }
}

.section-variants {
  background-color: transparent;
  /* Upewnij się, że kolor tła jest transparentny */
  opacity: 1 !important;
  /* Upewnij się, że przejrzystość jest ustawiona na 1 (brak przejrzystości) */
  /* Inne style dotyczące sekcji "Variants" */
}



.img-brands {
  max-height: 320px;
}

#configFaq .btn-header-link {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29.3px;
  letter-spacing: 0.04em;
  text-align: left;

  @media (min-width: 768px) {
    font-size: 36px;
    line-height: 44px;
  }
}

#configFaq .btn-header-link:focus {
outline: none;
}

#configFaq a img {
  width: 30px;
  @media (min-width: 992px) {
  width: 40px;
  }
}

#configFaq .faq-img-xl {
  width: 765px;
}

#configFaq a[aria-expanded="true"] img {
  transform: rotate(180deg);
  padding-right: 0;
}

#configFaq a[aria-expanded="false"] img {
  filter: invert(50%) sepia(91%) saturate(1320%) hue-rotate(170deg) brightness(94%) contrast(85%);
}

img.config-phone {
  max-height: 425px;
}

img.config-graphic {
  max-height: 300px;
}

// VR experiences

.vr-exp-people,
.vr-train-copy-processx {
  max-height: 487px;
}

.vr-exp-google-img,
.vr-train-google-img {
  max-height: 300px;
}

.vr-exp-circles {
  max-height: 287px;
}


.vr-exp-person-img {
  max-height: 330px;
}

.vr-exp-why-icon {
  max-height: 90px;

  @media (min-width: 992px) {
    max-height: 146px;
  }
}


.vr-exp-btn-group-wrapper,
.mr-btn-group-wrapper,
.ar-btn-group-wrapper {
  width: 104px;
  height: 36px;
  background: #EBF3F7;
  border: 0.440637px solid rgba(35, 160, 223, 0.15);
  box-shadow: 4.40637px 4.40637px 8.81273px rgba(65, 178, 234, 0.12), -4.40637px -4.40637px 8.81273px #F5FCFF;
  border-radius: 4.40637px;

  @media (min-width: 992px) {
    width: 288px;
    height: 84px;
    border: 1px solid rgba(35, 160, 223, 0.15);
    box-shadow: 10px 10px 20px rgba(65, 178, 234, 0.12), -10px -10px 20px #F5FCFF;
    border-radius: 10px;
  }
}

.vr-exp-toggleWrapper,
.mr-btn-group-wrapper,
.ar-btn-group-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

.vr-exp-toggleWrapper input,
.mr-toggleWrapper input,
.ar-toggleWrapper input {
  position: absolute;
  left: -99em;
}

.vr-exp-toggle,
.mr-toggle,
.ar-toggle {
  cursor: pointer;
  display: inline-block;
  position: relative;
  width: 88px;
  height: 26px;
  border-radius: 5px;
  margin-bottom: 0;
  -webkit-transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);

  @media (min-width: 992px) {
    width: 288px;
    height: 84px;
  }
}

.vr-exp-toggle:before,
.vr-exp-toggle:after,
.mr-toggle:before,
.mr-toggle:after,
.ar-toggle:before,
.ar-toggle:after {
  position: absolute;
  line-height: 33px;
  font-size: 13.2px;
  font-weight: 700;
  z-index: 2;
  -webkit-transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);

  @media (min-width: 992px) {
    font-size: 30px;
    line-height: 80px;
  }
}

.vr-exp-toggle:before {
  content: "MR";
  left: 10px;
  color: white;

  @media (min-width: 992px) {
    left: 55px;
  }
}

.mr-toggle:before {
  content: "VR";
  left: 10px;
  color: white;

  @media (min-width: 992px) {
    left: 55px;
  }
}

.ar-toggle:before {
  content: "VR";
  left: 10px;
  color: white;

  @media (min-width: 992px) {
    left: 55px;
  }
}

.vr-exp-toggle:after,
.mr-toggle:after {
  content: "AR";
  right: 10px;
  color: #23a0df;

  @media (min-width: 992px) {
    right: 55px;
  }
}

.ar-toggle:after {
  content: "MR";
  right: 10px;
  color: #23a0df;

  @media (min-width: 992px) {
    right: 55px;
  }
}

.vr-exp-toggle__handler,
.mr-toggle__handler,
.ar-toggle__handler {
  display: inline-block;
  position: relative;
  z-index: 1;
  background: #23a0df;
  border: 0.881273px solid #69BFEA;
  width: 45px;
  height: 26px;
  border-radius: 5px;
  top: 4px;
  left: 5px;
  -webkit-transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  -webkit-transform: translateX(0px);
  transform: translateX(0px);

  @media (min-width: 992px) {
    width: 132px;
    height: 60px;
    border: 1px solid #69BFEA;
    left: 12px;
    top: 12px;
    border-radius: 10px;
  }
}

input:checked+.vr-exp-toggle:before,
input:checked+.mr-toggle:before,
input:checked+.ar-toggle:before,
  {
  color: #23a0df
}

input:checked+.vr-exp-toggle:after,
input:checked+.mr-toggle:after,
input:checked+.ar-toggle:after {
  color: white;
}

input:checked+.vr-exp-toggle .vr-exp-toggle__handler,
input:checked+.mr-toggle .mr-toggle__handler,
input:checked+.ar-toggle .ar-toggle__handler,
  {
  width: 45px;
  -webkit-transform: translateX(40px);
  transform: translateX(40px);

  @media (min-width: 992px) {
    width: 132px;
    -webkit-transform: translateX(130px);
    transform: translateX(130px);
  }
}

#exp-ar,
#mr-ar,
#ar-vr {
  display: none;
}

#vr-exp-btn {
  width: 45px;
  height: 36px;
  background: #EBF3F7;
  border: 0.440637px solid rgba(35, 160, 223, 0.15);
  box-shadow: 4.40637px 4.40637px 8.81273px rgba(65, 178, 234, 0.12), -4.40637px -4.40637px 8.81273px #F5FCFF;
  border-radius: 4.40637px;
  color: #23a0df;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  @media (min-width: 992px) {
    width: 132px;
    height: 84px;
    font-weight: 700;
    font-size: 30px;
  }

}

video.vr-train-video {
  height: auto;
  width: 100%;
  max-width: 398px;
}

.vr-train-eng-img {
  max-height: 552px;
}

.vr-train-dol-img {
  max-height: 91px;
}



.mr-icon-main {
  max-height: 250px;
}

.mr-icon {
  max-height: 80px;

  @media (min-width: 992px) {
    max-height: 183px;
  }
}


$theme-colors: ('vsh-blue': #23a0df,
  'vsh-lightblue': #ebf3f7,
  'vsh-lightgrey': #c4c4c5,
  'vsh-darkgrey': #55585f,
  'vsh-darkgreen': #092d36,
  'vsh-black': #272727,
);

$spacer: 1rem;
$spacers: (0: 0,
);

@for $i from 6 through 30 {
  $spacers: map-merge($spacers,
      ($i: ($i * $spacer,
        ),
      ));
}

@import 'bootstrap/scss/bootstrap';
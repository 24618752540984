@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

.fs-10-14 {
    font-size: 0.625rem;
    line-height: 0.75rem;

    @media (min-width: 768px) {
        font-size: 0.875rem;
        line-height: 1.0625rem;
    }
}

.fs-11-20 {
    font-size: 0.688rem;
    line-height: 155%;

    @media (min-width: 768px) {
        font-size: 1.25rem;
        line-height: 155%;
    }
}

.fs-12 {
    font-size: 0.75rem;
    line-height: 1rem;
}

.fs-12-22 {
    font-size: 0.75rem;
    line-height: 1rem;

    @media (min-width: 768px) {
        font-size: 1.375rem;
        line-height: 2rem;
    }
}

.fs-14-20 {
    font-size: 0.875rem;
    line-height: 1.0625rem;

    @media (min-width: 768px) {
        font-size: 1.25rem;
        line-height: 125%;
    }
}

.fs-14-22 {
    font-size: 0.875rem;
    line-height: 1.0625rem;

    @media (min-width: 768px) {
        font-size: 1.375rem;
        line-height: 2rem;
    }
}

.fs-14-24 {
    font-size: 0.875rem;
    line-height: 1.0625rem;

    @media (min-width: 768px) {
        font-size: 1.5rem;
        line-height: 2.25rem;
    }
}

.fs-14-24-wide {
    font-size: 0.875rem;
    line-height: 180%;

    @media (min-width: 768px) {
        font-size: 1.5rem;
        line-height: 2.25rem;
    }
}

.fs-14-28 {
    font-size: 0.875rem;
    line-height: 131%;

    @media (min-width: 768px) {
        font-size: 1.75rem;
        line-height: 131%;
    }
}

.fs-14-26 {
    font-size: 0.875rem;
    line-height: 131%;

    @media (min-width: 768px) {
        font-size: 1.625rem;
        line-height: 131%;
    }
}

.fs-16-22 {
    font-size: 1rem;
    line-height: 1.5rem;

    @media (min-width: 768px) {
        font-size: 1.375rem;
        line-height: 2rem;
    }
}

.fs-18-24 {
    font-size: 1.125rem;
    line-height: 1.375rem;

    @media (min-width: 768px) {
        font-size: 1.5rem;
        line-height: 1.8125rem;
    }
}

.fs-18-28 {
    font-size: 1.125rem;
    line-height: 1.75rem;

    @media (min-width: 768px) {
        font-size: 1.75rem;
        line-height: 131%;
    }
}

.fs-18-32 {
    font-size: 1.125rem;
    line-height: 1.75rem;

    @media (min-width: 768px) {
        font-size: 2rem;
        line-height: 2.438rem;
    }
}

.fs-18-50 {
    font-size: 1.125rem;
    line-height: 1.75rem;

    @media (min-width: 768px) {
        font-size: 3.125rem;
        line-height: 3.813rem;
    }
}

.fs-22-18 {
    font-size: 1.375rem;
    line-height: 1.6875rem;

    @media (min-width: 768px) {
        font-size: 1.125rem;
        line-height: 1.375rem;
    }
}

.fs-22-30 {
    font-size: 1rem;
    line-height: 1.5rem;

    @media (min-width: 768px) {
        font-size: 1.375rem;
        line-height: 2rem;
    }
}

.fs-24-40 {
    font-size: 1.5rem;
    line-height: 1.8125rem;

    @media (min-width: 768px) {
        font-size: 2.5rem;
        line-height: 3.0625rem;
    }
}

.fs-24-36 {
    font-size: 1.5rem;
    line-height: 1.8125rem;

    @media (min-width: 768px) {
        font-size: 2.25rem;
        line-height: 2.75rem;
    }
}

.fs-24-55 {
    font-size: 1.5rem;
    line-height: 1.8125rem;

    @media (min-width: 768px) {
        font-size: 3.4375rem;
        line-height: 4.188rem;
    }
}

.fs-32-55 {
    font-size: 2rem;
    line-height: 2.4375rem;

    @media (min-width: 768px) {
        font-size: 3.4375rem;
        line-height: 4.188rem;
    }
}

.fs-36-55 {
    font-size: 2.35rem;
    line-height: 2.625rem;

    @media (min-width: 768px) {
        font-size: 3.4375rem;
        line-height: 4.188rem;
    }
}

.fs-55 {
    font-size: 3.4375rem;
    line-height: 4.188rem;
}

.font-weight-300 {
    font-weight: 300;
}

.font-weight-500 {
    font-weight: 500;
}

.font-weight-600 {
    font-weight: 600 !important;
}

.font-weight-lg-600 {
    @media (min-width: 1200px) {
        font-weight: 600;
    }
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.config-font-body {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17.5px;

    @media (min-width: 768px) {
        font-size: 20px;
        line-height: 130%;
    }
}

.config-font-body-bold {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;

    @media (min-width: 768px) {
        font-size: 24px;
        line-height: 180%;
    }
}

.config-font-main-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 45px;

    @media (min-width: 768px) {
        font-size: 55px;
        line-height: 67px;
    }
}

.config-font-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29.3px;
    letter-spacing: 0.04em;

    @media (min-width: 768px) {
        font-size: 36px;
        line-height: 44px;
    }
}

.config-font-label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 10px;
    line-height: 12.2px;

    @media (min-width: 768px) {
        font-size: 14px;
        line-height: 17px;
    }
}